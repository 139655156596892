var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card-title',[_c('v-row',{staticClass:"py-1 px-3 grey lighten-3",attrs:{"align":"center","no-gutters":""}},[_c('h4',{staticClass:"grey--text text--darken-2 font-weight-medium"},[_vm._v("Pedidos de Sedes")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"success","small":"","depressed":""},on:{"click":function($event){return _vm.$router.push('/headquarter-orders/new-order')}}},[_vm._v(" "+_vm._s(_vm.lang.create)+" ")]),_c('v-divider',{staticClass:"mx-3",attrs:{"vertical":""}}),_c('v-btn',{staticClass:"mr-4",attrs:{"color":"success","small":"","depressed":"","disabled":_vm.sendButtonDisabled},on:{"click":_vm.sendHeadquarterOrders}},[_vm._v(" "+_vm._s(_vm.lang.send)+" ")])],1)],1),_c('v-card-text',[_c('v-row',{attrs:{"no-gutters":"","dense":"","justify":"center"}},[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"height":"400px","items":_vm.headquarterOrders.items,"hide-default-footer":"","disable-sort":"","fixed-header":"","show-select":!_vm.isCommercial},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(){return undefined},proxy:true},{key:"item.data-table-select",fn:function(ref){
var item = ref.item;
var isSelected = ref.isSelected;
var select = ref.select;
var props = ref.props;
return [(!_vm.isSelectable(item))?_c('v-simple-checkbox',{attrs:{"value":isSelected,"ripple":false},on:{"input":function($event){return select($event)}}}):_vm._e()]}},{key:"header.id",fn:function(ref){
var header = ref.header;
return [_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-sheet',{staticClass:"mb-1",attrs:{"width":"100%","height":"40px"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)]}},{key:"header.reference",fn:function(ref){
var header = ref.header;
return [_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"rounded-0 custom-label-color font-weight-medium mb-1",attrs:{"clearable":"","hide-details":"auto","outlined":"","dense":"","color":"indigo","label":_vm.lang.search,"append-icon":"mdi-magnify"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.refresh($event)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)]}},{key:"header.createdBy",fn:function(ref){
var header = ref.header;
return [_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{staticClass:"rounded-0 transparent font-weight-medium custom-label-color mb-1",attrs:{"hide-details":"auto","dense":"","flat":"","label":"Filtrar por gestor","outlined":"","item-text":"commercialName","item-value":"id","hide-no-data":"","hide-selected":"","clearable":"","placeholder":_vm.lang.typeToSearch},on:{"change":_vm.refresh}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)]}},{key:"header.headquarter",fn:function(ref){
var header = ref.header;
return [_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{staticClass:"rounded-0 transparent font-weight-medium custom-label-color mb-1",attrs:{"hide-details":"auto","dense":"","flat":"","label":_vm.lang.headquarters,"clearable":"","loading":_vm.loading,"outlined":"","item-text":"name","return-object":"","autocomplete":"null","placeholder":_vm.lang.typeToSearch},on:{"change":_vm.refresh},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-list-item-content',{staticClass:"my-2"},[_c('v-list-item-title',[_c('h3',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(data.item.name)+" "+_vm._s(data.item.lastname))])]),_c('v-list-item-subtitle',[_vm._v(_vm._s(data.item.commercialName))])],1)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)]}},{key:"header.createdAt",fn:function(ref){
var header = ref.header;
return [_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-1",attrs:{"tile":"","outlined":"","width":"100%","min-height":"40px","color":"#9e9e9e"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.lang.filterByDate)+" "),_c('v-spacer'),_c('v-icon',{staticClass:"ma-0 pa-0",attrs:{"dense":""}},[_vm._v("mdi-calendar-clock-outline")])],1)]}}],null,true)},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-date-picker',{attrs:{"year-icon":"mdi-calendar-blank","multiple":"","range":"","next-icon":"mdi-skip-next","prev-icon":"mdi-skip-previous"},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}})],1),_c('v-card-actions',[_c('v-btn',{staticClass:"text-capitalize",attrs:{"depressed":"","tile":"","color":"pb-grey"},on:{"click":function($event){_vm.dates = []; _vm.refresh();}}},[_vm._v(" "+_vm._s(_vm.lang.clearFilters)+" ")]),_c('v-spacer'),_c('v-btn',{staticClass:"text-capitalize",attrs:{"depressed":"","tile":"","color":"pb-success"},on:{"click":_vm.refresh}},[_vm._v(" "+_vm._s(_vm.lang.search)+" ")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)]}},{key:"item.createdBy",fn:function(ref){
var item = ref.item;
return [_c('v-card',{attrs:{"outlined":""}},[_c('div',[(item.createdBy.name)?[_vm._v(" "+_vm._s(item.createdBy.commercialName)+" "),_c('br')]:_vm._e(),_vm._v(" "+_vm._s(item.createdBy.email)+" ")],2)])]}},{key:"header.status",fn:function(ref){
var header = ref.header;
return [_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{staticClass:"rounded-0 transparent font-weight-medium custom-label-color mb-1",attrs:{"hide-details":"auto","dense":"","flat":"","label":_vm.lang.filterByStatus,"items":_vm.statuses,"outlined":"","autocomplete":"null","item-text":"name","item-value":"value","clearable":""},on:{"change":_vm.refresh}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)]}},{key:"header.actions",fn:function(ref){
var header = ref.header;
return [_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-sheet',{staticClass:"mb-1",attrs:{"width":"100%","height":"40px"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)]}},{key:"header.totalAmount",fn:function(ref){
var header = ref.header;
return [_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-sheet',{staticClass:"mb-1",attrs:{"width":"100%","height":"40px"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"font-weight-bold grey--text text--darken-2"},[_vm._v(" "+_vm._s(item.createdAt.setLocale("es").toFormat('dd/MM/yy $ HH:mm').replace("$", _vm.lang.hour + ":"))+" ")])]}},{key:"item.totalAmount",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center align-center font-weight-bold grey--text text--darken-2"},[_vm._v(" "+_vm._s(item.totalPvp)+"€ ")])]}},{key:"item.headquarter",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"outlined":""},on:{"click":function($event){return _vm.onHeadquarterClicked(item)}}},[_c('span',{staticClass:"grey--text text--darken-2 font-weight-bold"},[_vm._v(" "+_vm._s(item.headquarter.email)+" ")])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('OrderStatusComponent',{attrs:{"orderType":item.status}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"left":"","transition":"slide-x-transition","rounded":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"width":"250","outlined":"","flat":"","color":"#5D6162","dark":""}},[_c('v-subheader',[_vm._v(_vm._s(_vm.lang.options))]),_c('div',{staticClass:"px-3"},[_c('v-divider')],1),(!_vm.isDisableEdit(item))?_c('v-list-item',{on:{"click":function($event){return _vm.$router.push('/headquarter-orders/' + item.id + '/edit')}}},[_c('v-list-item-icon',{staticClass:"mr-5"},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.lang.editAnOrder))])],1)],1):_vm._e(),(!_vm.isDisableEdit(item))?_c('v-list-item',{on:{"click":function($event){return _vm.deleteOrder(item.id)}}},[_c('v-list-item-icon',{staticClass:"mr-5"},[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.lang.delete))])],1)],1):_vm._e()],1)],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1),_c('v-card-actions',{staticClass:"pt-0"},[_c('v-progress-linear',{attrs:{"indeterminate":_vm.loading}}),_c('v-pagination',{staticClass:"custom-pagination",attrs:{"length":_vm.pageCount,"total-visible":8},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),_c('h5',{staticClass:"ml-auto font-weight-medium grey--text text--darken-1"},[_vm._v(_vm._s(_vm.lang.ordersTableTip))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }