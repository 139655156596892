import HeadquarterOrder from "@/models/HeadquarterOrder";
import axios from "axios";
import ConstantTool from "@/services/tool/ConstantTool";
import {getModule} from "vuex-module-decorators";
import SessionModule from "@/store/SessionModule";
import JsonTool from "@/services/tool/JsonTool";
import Response from "@/models/responses/Response";
import Order from "@/models/Order";
import SnackbarModule from "@/store/SnackbarModule";
import Headquarter from "@/models/Headquarter";

export default class HeadquarterOrderService {

    static async getById(id: number) {
        try {
            const response = await axios.get(ConstantTool.BASE_URL + "/api/headquarter-orders/" + id, {
                headers: { Authorization: getModule(SessionModule).session.token }
            })
            const order = JsonTool.jsonConvert.deserializeObject(response.data, HeadquarterOrder)
            return Promise.resolve({ result: order })
        } catch (e) { return Promise.reject(e) }
    }

    static async findAll(page: number, size: number, search: Nullable<string>): Promise<Response<HeadquarterOrder[]>> {
        try {
            const response = await axios.get(ConstantTool.BASE_URL + `/api/headquarter-orders`, {
                params: { page, size, search },
                headers: { Authorization: getModule(SessionModule).session.token }
            })
            const headquarterOrders = JsonTool.jsonConvert.deserializeArray(response.data, HeadquarterOrder)
            const xTotalCount = Number(response.headers["x-total-count"])
            return Promise.resolve({ result: headquarterOrders, xTotalCount })

        } catch (e) {
            return Promise.reject(e)
        }
    }

    static async create(request: HeadquarterOrder): Promise<Response<HeadquarterOrder>> {
        try {
            const response = await axios.post(ConstantTool.BASE_URL + `/api/headquarter-orders`, request,{
                headers: { Authorization: getModule(SessionModule).session.token }
            })
            const headquarterOrder = JsonTool.jsonConvert.deserializeObject(response.data, HeadquarterOrder)
            return Promise.resolve({ result: headquarterOrder })
        } catch (e) {
            return Promise.reject(e)
        }
    }

    static async draft(request: HeadquarterOrder): Promise<Response<HeadquarterOrder>> {
        try {
            const response = await axios.post(ConstantTool.BASE_URL + `/api/headquarter-orders/draft`, request, {
                headers: { Authorization: getModule(SessionModule).session.token }
            })
            const headquarterOrder = JsonTool.jsonConvert.deserializeObject(response.data, HeadquarterOrder)
            return Promise.resolve({ result: headquarterOrder })
        } catch (e) {
            return Promise.reject(e)
        }
    }

    static async send(id: number): Promise<Response<HeadquarterOrder>> {
        try {
            const response = await axios.patch(ConstantTool.BASE_URL + "/api/headquarter-orders/" + id + "/send", null, {
                headers: { Authorization: getModule(SessionModule).session.token }
            })
            const order = JsonTool.jsonConvert.deserializeObject(response.data, HeadquarterOrder)
            getModule(SnackbarModule).makeToast("Pedido enviado con exito")
            return Promise.resolve({ result: order })
        } catch (e) { return Promise.reject(e) }
    }

    static async patch(id: number, request: HeadquarterOrder) {
        try {
            const response = await axios.patch(ConstantTool.BASE_URL + "/api/headquarter-orders/" + id, request, {
                headers: { Authorization: getModule(SessionModule).session.token }
            })
            const order = JsonTool.jsonConvert.deserializeObject(response.data, HeadquarterOrder)
            getModule(SnackbarModule).makeToast("Pedido actualizado")
            return Promise.resolve({ result: order })
        } catch (e) { return Promise.reject(e) }
    }

    static async delete(id: number) {
        try {
            const response = await axios.delete(ConstantTool.BASE_URL + "/api/headquarter-orders/" + id, {
                headers: { Authorization: getModule(SessionModule).session.token }
            })
            getModule(SnackbarModule).makeToast("Pedido eliminado")
            return Promise.resolve()
        } catch (e) { return Promise.reject(e) }
    }

}